<template>
  <div class="content-wrapper">
    <div class="order main-content">
      <div class="page-header">
        <h1>Add Order</h1>
        <OrderForm :viewMode="false" :eventName="'addOrder'" @addOrder="addOrder"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import OrderForm from '@/components/sales/OrderForm.vue';
import Common from '@/lib/common';
import Sales from '@/lib/sales';

export default {
  name: 'AddOrder',
  components: {
    OrderForm,
  },
  methods:{
    async addOrder(form){
      try{
        const orderInfo = Object.assign({}, form);
        const loginInfo = Common.getLoginInfo();
        const customer = await this.getCustomerByName(form.customerId);
        const customerId = customer.id;

        orderInfo.customerId = customerId;
        const order = await Sales.createOrder(this.apiUrl, orderInfo, loginInfo);
      }catch(err){
        console.log(err);
      }
    },
    async getCustomerByName(memberName){
      const loginInfo = Common.getLoginInfo();
      const searchFields = {
        'name': memberName,
      };
      const customers = await Sales.searchCustomer(this.apiUrl, searchFields, loginInfo);
      const customer = customers[0];
      return customer;
    },
  },
  computed: mapState({
    apiUrl: state => state.apiUrl,
  }),
}
</script>

<style lang="scss">
.page-header{
  h1{
    margin-bottom: 50px;
  }
}
</style>
